import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Columns, Heading } from "react-bulma-components"
import { AiFillEye } from "react-icons/ai"

const insideBook = [
  "Formuler une raison d'être opérationnelle qui met l'équipe en mouvement",
  "3 méthodes pour identifier les rôles et répartir les responsabilités au sein de l'équipe",
  "Plusieurs approches pour piloter l'activité dans un contexte incertain et complexe",
  "Des outils pratiques pour encourager et accompagner l'autonomie et la prise d'initiative des membres de l'équipe",
  "Les comportements à adopter pour favoriser l'autonomie, l'engagement et la résilience ",
  "Une démarche pragmatique pour atteindre vos ambitions professionnelles, qu'elles soient personnelles ou collectives, particulièrement adapté aux contextes incertains.",
]
const BookToc = (props) => {
  const showLink = props.showLink ?? false

  return (
    <div className="inside_inner">
      <Columns>
        <Columns.Column size="half">
          <StaticImage
            className="ml-6"
            src="../images/mockup_hand.png"
            height={500}
          />
          {showLink ? (
            <p className="viewtoc">
              <Link to={showLink}>
                <AiFillEye /> &nbsp; View table of contents
              </Link>
            </p>
          ) : (
            ""
          )}
        </Columns.Column>
        <Columns.Column size="half">
          <Heading renderAs="h2" size="3">
            A l'intérieur
          </Heading>
          <ul>
            {insideBook.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
            {/*             <li className="not_in">No upsale, no fluff</li>
             */}{" "}
          </ul>
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default BookToc
