import React from "react"
import { Heading, Icon } from "react-bulma-components"
import {
  AiOutlineCheckCircle,
  AiOutlineTeam,
  AiOutlineFullscreenExit,
  AiOutlineGold,
} from "react-icons/ai"

const BenefitItem = ({ renderIcon, title, children }) => {
  const OutlineIcon = renderIcon
    ? renderIcon
    : () => (
        <Icon color="primary">
          <AiOutlineCheckCircle />
        </Icon>
      )
  return (
    <li className="book_benefits_item">
      <OutlineIcon />
      <span>
        <span className="item_title">{title}</span> &mdash;{" "}
        <span className="item_details">{children}</span>
      </span>
    </li>
  )
}

const BookBenefits = () => {
  return (
    <div className="book_benefits">
      <Heading renderAs="h2" size="3">
        Qu'apprendrez vous de ce livre ?
      </Heading>
      <ul>
        <BenefitItem
          renderIcon={() => (
            <Icon color="primary">
              <AiOutlineTeam />
            </Icon>
          )}
          title="Une autre façon de s'organiser collectivement"
        >
          pour favoriser l'autonomie, la réactivité et la prise d'initiatives
          dans l'équipe
        </BenefitItem>

        <BenefitItem
          renderIcon={() => (
            <Icon color="primary">
              <AiOutlineFullscreenExit />
            </Icon>
          )}
          title="Une nouvelle approche du management"
        >
          adapté à l'incertitude et à la complexité qui caractérisent le monde
          d'aujourd'hui
        </BenefitItem>

        <BenefitItem
          renderIcon={() => (
            <Icon color="primary">
              <AiOutlineGold />
            </Icon>
          )}
          title="Mettre l'incertitude à profit dans vos décisions"
        >
          et rester en mouvement, y compris quand les prévisions ne peuvent être
          fiables
        </BenefitItem>
      </ul>
    </div>
  )
}

export default BookBenefits
