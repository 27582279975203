import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Columns, Heading } from "react-bulma-components"

const HeroBook = () => {
  return (
    <Columns className="hero_book">
            <Columns.Column size="one-third">
            <StaticImage src="../images/mockup2.png" alt="2apex le livre" />
      </Columns.Column>


      <Columns.Column size="two-thirds">
        <div className="intro">
          <p className="author">
            <figure className="image is-48x48">
              <img
                className="is-rounded"
                src="../thierry.png"
                alt="Thierry Malo"
              />
            </figure>
          </p>
          <Heading renderAs="h1" size="1">
            Une nouvelle approche du management
          </Heading>
          <p className="description">
            Une approche du management pratique et concrète, pour progresser
            vers vos ambitions dans un monde de plus en plus incertain.
          </p>
{/*           <Button size="large" color="primary">
            Buy Now
          </Button>
 */}        </div>{" "}
      </Columns.Column>

    </Columns>
  )
}

export default HeroBook
