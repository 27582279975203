import React from "react"
import { Container } from "react-bulma-components"
import FooterNavbar from "../components/FooterNavbar"

const PageFooter = () => {
  return (
    <footer className="page_footer">
      <Container max breakpoint="desktop">
        <FooterNavbar />
      </Container>
    </footer>
  )
}

export default PageFooter
