import React from "react"
import { Heading, Icon } from "react-bulma-components"
import { FaTwitterSquare, FaEnvelopeSquare } from "react-icons/fa"
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

const SidebarAuthor = () => {
  return (
    <>
      <Heading renderAs="h2">
        About the author
      </Heading>
      <figure className="image is-square">
        <img className="is-rounded-s" src="../thierry.png" alt="Thierry Malo" />
      </figure>

      <StaticQuery
        query={graphql`
          query AsideQuery {
            site {
              siteMetadata {
                author {
                  summary
                }
                social {
                  twitter
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div>
              <a
                href={`https://twitter.com/${data.site.siteMetadata.social.twitter}`}
              >
                <Icon size="medium" color="primary">
                  <FaTwitterSquare size={24} />
                </Icon>
              </a>
              <Icon size="medium" color="primary">
                <FaEnvelopeSquare size={24} />
              </Icon>
            </div>

            <ReactMarkdown className="presentation">
              {data.site.siteMetadata.author.summary}
            </ReactMarkdown>
          </>
        )}
      />
    </>
  )
}

export default SidebarAuthor
