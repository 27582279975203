import React from 'react'
const axios = require('axios').default

class NewsletterHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      FNAME: '',
      LNAME: '',
      EMAIL: '',
      INTERESTS: ['article'],
      error: null,
      inprogress: false,
      success: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ inprogress: true, success: false })
    const me = this
    const payload = {
      FNAME: this.state.FNAME,
      LNAME: this.state.LNAME,
      EMAIL: this.state.EMAIL,
      INTERESTS: this.state.INTERESTS,
    }

    if (this.state.city_field !== undefined) {
      const error = { code: 500, message: 'No robot allowed' }
      this.setState({ error, inprogress: false })
      return
    }

    axios
      .post('/api/newsletter', { 'form-name': 'subscribe', ...payload })
      .then(
        function (response) {
          console.log(response.data)
          me.setState({ inprogress: false, success: true })
        },
        (error) => {
          me.setState({ error: error.response.data, inprogress: false })
        }
      )
      .catch(function (error) {
        me.setState({ error, inprogress: false })
      })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    if (target.type !== 'checkbox') {
      this.setState({
        [name]: value,
      })
    } else {
      if (target.checked) {
        var joined = this.state[name].concat(value)
        this.setState({
          [name]: joined,
        })
      } else {
        var netState = this.state[name].filter((v) => v !== value)
        this.setState({
          [name]: netState,
        })
      }
    }
  }

  render() {
    return this.props.render({
      state: this.state,
      handleChange: this.handleInputChange,
      handleSubmit: this.handleSubmit,
      clearSuccess: () => this.setState({ success: false }),
      clearError: () => this.setState({ error: null }),
    })
  }
}

export default NewsletterHandler
