import React from 'react'
import { Button, Notification, Progress } from 'react-bulma-components'

const SubmitForm = ({
  isSuccess,
  hasError,
  isInProgress,
  clearSuccess,
  clearError,
  children,
  ...props
}) => {

  return (
    <div {...props}>
      {isSuccess ? (
        <Notification color="success">
          Opération réussie
          <Button remove onClick={clearSuccess} />
        </Notification>
      ) : (
        ' '
      )}

      {hasError ? (
        <Notification color="danger">
          {hasError.message ?? 'Something went wrong'}
          <Button remove onClick={clearError} />
        </Notification>
      ) : (
        ' '
      )}

      {isInProgress ? <Progress size="small" max={100} color="primary" /> : ' '}

      <Button.Group align="right">
        <Button color="primary" type="submit" disabled={isInProgress}>
          {children}
        </Button>
      </Button.Group>
    </div>
  )
}

export default SubmitForm
