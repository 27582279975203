import React from "react"
import { Link, graphql, navigate } from "gatsby"
import { Heading, Pagination, Content, Container } from "react-bulma-components"
import Page from "../layouts/Page"
import ShareButtons from "../components/ShareButtons"

const NewsListPage = (props) => {
  const { data, pageContext, location } = props
  return (
    <Page {...props}>
      <Container
        max
        breakpoint="desktop"
      >
        <Heading renderAs="h1">En ce moment</Heading>
        {data.posts.nodes.map((post, index) => {
          const relativeUrl = `/news/${post.frontmatter.preslug}/${post.slug}`
          const url = `${location.origin}${relativeUrl}`
          const title = post.frontmatter.title

          return (
            <article className="news_post post single" key={index}>
              <Heading renderAs="h2">
                <Link to={relativeUrl}>{title}</Link>
              </Heading>
              <Content
                className="post_content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />

              <p className="byline">
                <span className="date">Le {post.frontmatter.pubDate}</span>
              </p>
              <div className="post-footer">
                <ShareButtons post={{ url, title }} />
              </div>
            </article>
          )
        })}
        <Pagination
          align="center"
          size="small"
          mt={4}
          delta={2}
          total={pageContext.numPages}
          current={pageContext.currentPage}
          onChange={newPage => {
            if (newPage === 1) {
              navigate(pageContext.basePath)
              return
            }
            navigate(pageContext.basePath + "/page/" + newPage)
          }}
        />
      </Container>
    </Page>
  )
}

export default NewsListPage

export const pageQuery = graphql`
  query getPosts($skip: Int!, $limit: Int!) {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { published: { eq: true } }
        sourceInstanceName: { eq: "news" }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        slug
        frontmatter {
          title
          preslug: date(formatString: "YYYY/MM")
          pubDate: date(formatString: "DD MMMM YYYY", locale: "fr")
        }
        html
      }
    }
  }
`
