import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Navbar } from "react-bulma-components"

const FooterNavbar = () => {
  const data = useStaticQuery(graphql`
    query BottomMenu {
      site {
        siteMetadata {
          title
          description
        }
      }
      allMenuYaml(filter: { menu: { in: "bottom" } }) {
        nodes {
          title
          url
        }
      }
    }
  `)

  return (
    <Navbar>
      <Navbar.Brand>
        <div className="footer_text">
          <div className="footer_title">
            {data.site.siteMetadata.description}
          </div>
          <div className="footer_subtitle">
            {data.site.siteMetadata.copyright}
          </div>
        </div>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container align="right">
          {data.allMenuYaml.nodes.map((node, index) => (
            <Navbar.Item key={index} href={node.url}>
              {node.title}
            </Navbar.Item>
          ))}
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  )
}

export default FooterNavbar
