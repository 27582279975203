import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Heading, Media } from "react-bulma-components"
import { FaPencilAlt } from "react-icons/fa"
import ReactMarkdown from "react-markdown"

const AuthorBlock = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const showLink = props.showLink ?? false

  return (
    <div className="author_block">
      <Heading renderAs="h2" size="3">
        L'auteur
      </Heading>
      <Media>
        <Media.Item align="left">
          <figure className=" image is-64x64">
            <img
              className="is-rounded"
              src="../thierry.png"
              alt="Thierry Malo"
            />
          </figure>
        </Media.Item>
        <Media.Item>
          <div class="author_text">
            <p>
              <strong>{data.site.siteMetadata.author.name}</strong>
            </p>
            <ReactMarkdown className="presentation">
              {data.site.siteMetadata.author.summary}
            </ReactMarkdown>
            {showLink ? (
              <p>
                <Link
                  to={showLink}
                  className="link-with-icon link-with-icon--feather"
                >
                  <FaPencilAlt /> &nbsp; Read about why I wrote this book
                </Link>
              </p>
            ) : (
              ""
            )}
          </div>
        </Media.Item>
      </Media>
    </div>
  )
}

export default AuthorBlock
