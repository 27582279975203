import React from "react"
import {
  Button,
  Columns,
  Form,
  Heading,
  Progress,
  Notification,
} from "react-bulma-components"
import SvgMail from "./svgMail"
import NewsletterHandler from "./NewsletterHandler"

const NewsletterBlock = props => {
  const title = props?.title ?? "Soyez informé de la sortie de l'ouvrage"
  const description =
    props?.description ??
    "Vos informations ne seront pas transmises à des tiers et je vous  promets de ne pas trop vous solliciter."
  return (
    <Columns className="newsletter_block">
      <Columns.Column size="one-quarter">
        <SvgMail width={120} fill="#3586f8" />
      </Columns.Column>

      <Columns.Column size="three-quarters">
        <Heading renderAs="h2" size="5">
          {title}
        </Heading>
        <p className="description">{description}</p>
        <NewsletterHandler
          render={({
            state,
            handleChange,
            handleSubmit,
            clearError,
            clearSuccess,
          }) => {
            const { FNAME, LNAME, EMAIL } = state

            return (
              <form
                onSubmit={handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="city_field"
                name="subscribe"
              >
                {state.inprogress ? (
                  <Progress size="small" max={100} color="primary" />
                ) : (
                  " "
                )}
                {state.success ? (
                  <Notification color="success">
                    Opération réussie
                    <Button remove onClick={clearSuccess} />
                  </Notification>
                ) : (
                  " "
                )}

                {state.error ? (
                  <Notification color="danger">
                    {state.error.message ?? "Something went wrong"}
                    <Button remove onClick={clearError} />
                  </Notification>
                ) : (
                  " "
                )}

                <Columns className="nl_controls" >
                  <Columns.Column size="half">
                      <Form.Control>
                        <Form.Input
                          name="FNAME"
                          type="text"
                          placeholder="Prénom"
                          value={FNAME}
                          required
                          onChange={handleChange}
                          size="small"
                        />
                      </Form.Control>
                  </Columns.Column>

                  <Columns.Column size="half">
                      <Form.Control>
                        <Form.Input
                          name="LNAME"
                          type="text"
                          placeholder="Nom"
                          value={LNAME}
                          required
                          onChange={handleChange}
                          size="small"
                        />
                      </Form.Control>
                  </Columns.Column>
                  <Columns.Column size="two-third">
                      <Form.Control className="is-expanded">
                        <Form.Input
                          name="EMAIL"
                          type="email"
                          placeholder="Votre email"
                          value={EMAIL}
                          required
                          onChange={handleChange}
                          size="small"
                        />
                      </Form.Control>
                  </Columns.Column>

                  <Columns.Column size="one-third">
                      <Button
                        color="primary"
                        size="small"
                        type="submit"
                        disabled={state.inprogress}
                        className="nl_button"
                      >
                        Envoyer
                      </Button>
                      {/*                   <SubmitForm
                    isSuccess={state.success}
                    hasError={state.error}
                    isInProgress={state.inprogress}
                    clearSuccess={clearSuccess}
                    clearError={clearError}
                    size="small"
                  >
                    Envoyer
                  </SubmitForm>
 */}{" "}
                  </Columns.Column>
                </Columns>
              </form>
            )
          }}
        />
      </Columns.Column>
    </Columns>
  )
}

export default NewsletterBlock
