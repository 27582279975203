import React from "react"
import PageFooter from "./PageFooter"
import PageHeader from "./PageHeader"

const Page = ({ children }) => {
    return (
      <>
        <PageHeader />
        {children}
        <PageFooter />
      </>
    )
  }
  
  export default Page
  