import React from "react"
import { Container, Heading, Icon } from "react-bulma-components"
import { AiOutlineCheckCircle } from "react-icons/ai"
import HeroBook from "../components/HeroBook"
import UserReview from "../components/UserReview"
import NewsletterBlock from "../components/NewsletterBlock"
import Audience from "../components/Audience"
import AuthorBlock from "../components/AuthorBlock"
import BookToc from "../components/BookToc"
import BookBenefits from "../components/BookBenefits"
import Page from "../layouts/Page"

export default function Home() {
  return (
    <Page>
      <Container max breakpoint="desktop" renderAs="main">
        <section className="hero_section">
          <HeroBook />
        </section>
        <section className="backflip_section">
          <Heading size={2} renderAs="h2">
            <Icon color="primary">
              <AiOutlineCheckCircle />
            </Icon>
            Need new ideas? Done.
          </Heading>
          <p className="description">
            Le management d'aujourd'hui doit tenir compte du fait que le monde
            est de plus en plus incertain et complexe. Avec ce livre, vous
            apprendrez les techniques et les méthodes pour que chaque membre de
            l'organisation puisse réagir de façon adaptée à des changements
            incessants et imprévisibles de votre environnment.
          </p>
        </section>

        <section className="companies_section">
          <Heading renderAs="h2" size="1">
            Read by professionals working at:
          </Heading>
        </section>
        <hr className="divider" />

        <section className="learn_section">
          <BookBenefits />
        </section>

        <section className="inside_section">
          <BookToc />
        </section>

        <figure className="quote">
          <blockquote className="quote_content">
            The way to get <em>good</em> ideas is to get <em>lots</em> of
            ideas...
          </blockquote>
          <figcaption className="quote_author">
            <figure className="author_photo image is-32x32">
              <img
                className="is-rounded"
                src="../henrymintzberg.jpg"
                alt="Henry Mintzberg"
              />
            </figure>
            Henry Mintzberg, Academic and author on business and management
          </figcaption>
        </figure>

        <section className="review_section">
          <Heading renderAs="h2" size="3">
            What readers say
          </Heading>
          <div className="review_list">
            <UserReview />
            <UserReview />
          </div>
        </section>

        <Audience title="Qui gagnerait à lire ce livre ?">
          <Audience.List>
            <Audience.ListItem
              title="Managers"
              items={[
                "Accompagnez votre équipe vers plus d'autonomie.",
                "Alignez votre organisation sur vos ambitions collectives.",
                "Adaptez votre management pour mieux faire face à l'incertitude. ",
                "Sortez du pilotage par les urgences.",
              ]}
            />
            <Audience.ListItem
              title="Directrices, directeurs de la transformation"
              items={[
                "Favorisez l'adaptation et l'agilité, y compris chez les métiers.",
                "Affranchissez vous des réorganissations 'Big Bang'.",
                "Outillez la communication entre les managers métiers et ceux de l'IT.",
              ]}
            />
          </Audience.List>
        </Audience>

        <section className="newsletter_section">
          <NewsletterBlock />
        </section>

        <section className="author_section">
          <AuthorBlock />
        </section>
        <hr className="divider" />

        <section className="buy_section">
          <Heading renderAs="h2" size="3">
            Buy the book
          </Heading>
          <Heading subtitle>
            136 pages with <em>actionable</em> advice and no fluff.
          </Heading>
        </section>
      </Container>
    </Page>
  )
}
