import React from "react"
import Page from "../../layouts/Page"
import { Columns, Container, Heading } from "react-bulma-components"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SidebarAuthor from "../../components/SidebarAuthor"

export default function BlogPostTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark
  return (
    <Page>
      <Container
        max
        breakpoint="desktop"
        className="blogpost"
        renderAs="article"
      >
        <Columns>
          <Columns.Column desktop size="three-fifths" >
          <div className="published-at">Le {frontmatter.date}</div>
            <Heading renderAs="h1" size={3}>
              {frontmatter.title}
            </Heading>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <div className="book_block">
              <Columns>
                <Columns.Column size="one-fifth">
                  <StaticImage src="../../images/book_cover.jpg" alt='book cover' height={160} />
                </Columns.Column>
                <Columns.Column size="three-quarters">
                  <div className="intro">
                    <Heading renderAs="h3" size="5">
                      Generate Product Ideas
                    </Heading>
                    <p className="description">
                      A highly practical book for finding ideas for your next
                      business, indie startup or a weekend project.
                    </p>
                    <Link to="/index" className="learn_more">
                      Learn more
                    </Link>
                  </div>
                </Columns.Column>
              </Columns>
            </div>
          </Columns.Column>
          <Columns.Column desktop size="one-fifth" offset={1} renderAs="footer">
              <SidebarAuthor />
          </Columns.Column>
        </Columns>
      </Container>
    </Page>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(locale: "fr", formatString: "DD MMMM YYYY")
        title
      }
    }
  }
`
