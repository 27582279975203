import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bulma-components"
import NewsletterBlock from "../components/NewsletterBlock"
import HeroBook from "../components/HeroBook"
import AuthorBlock from "../components/AuthorBlock"
import BookBenefits from "../components/BookBenefits"
import Audience from "../components/Audience"
import Page from "../layouts/Page"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => {
  return (
    <Page>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:image" content="/images/2apex_10x14.jpg" />
      </Helmet>
      <Container max breakpoint="desktop" renderAs="main">
        <section className="hero_section">
          <HeroBook />
        </section>

        <section className="learn_section">
          <BookBenefits />
        </section>

        <section className="newsletter_section">
          <NewsletterBlock />
        </section>

        <Audience title="Qui gagnerait à lire ce livre ?">
          <Audience.List>
            <Audience.ListItem
              title="Managers"
              items={[
                "Accompagnez votre équipe vers plus d'autonomie.",
                "Alignez votre organisation sur vos ambitions collectives.",
                "Adaptez votre management pour mieux faire face à l'incertitude. ",
                "Sortez du pilotage par les urgences.",
              ]}
            />
            <Audience.ListItem
              title="Directrices, directeurs de la transformation"
              items={[
                "Favorisez l'adaptation et l'agilité, y compris chez les métiers.",
                "Affranchissez vous des réorganissations 'Big Bang'.",
                "Outillez la communication entre les managers métiers et ceux de l'IT.",
              ]}
            />
          </Audience.List>
        </Audience>

        <section className="author_section">
          <AuthorBlock />
        </section>
      </Container>
    </Page>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author {
          summary
        }
      }
    }
  }
`
