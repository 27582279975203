import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Columns, Container, Heading } from "react-bulma-components"
import Page from "../layouts/Page"
const ResumePage = () => {
  return (
    <Page>
      <Container max breakpoint="desktop" className="resume">
        <section>
          <Columns>
            <Columns.Column size="one-third">
              <StaticImage src="../images/thierry_malo.jpg" />
            </Columns.Column>
            <Columns.Column size="two-thirds">
              <Heading renderAs="h1" size={2}>
                Thierry Malo
              </Heading>
              <div className="presentation">
                <p>
                  Depuis 20 ans, Thierry pilote des projets d’innovation et
                  manage des équipes. Au cours de ses diverses expériences, il a
                  développé une pratique du leadership destinée à renforcer
                  l’engagement et l’agentivité des collaborateurs au service
                  d’un but commun dans des environnements complexes.
                </p>
                <p>
                  {" "}
                  Il a guidé des équipes dans l’adoption de cette pratique dans
                  un grand nombre de secteurs (Télécom, banque, social, etc.).
                  Depuis 5 ans, il se consacre à accompagner les managers et
                  dirigeants sur le chemin de ce nouveau leadership et du management distribué.
                </p>
              </div>
            </Columns.Column>
          </Columns>
        </section>

      </Container>
    </Page>
  )
}

export default ResumePage
