import React from "react"
import { Media } from "react-bulma-components"

const UserReview = () => {
  return (
    <Media>
      <Media.Item align="left">
        <figure className=" image is-64x64">
          <img
            className="is-rounded"
            src="../henrymintzberg.jpg"
            alt="Henry Mintzberg"
          />
        </figure>
      </Media.Item>
      <Media.Item>
        <blockquote className="review_quote">
          I’ve probably read every blog post and IndieHackers thread on finding
          ideas, so I was sceptical. Regardless, most of the techniques in this
          book turned out to be completely new to me. I’d defeintely recommend
          this book to all indie-hackers.
        </blockquote>
        <footer className="review_source">
          <span className="review_author">Marijus Merkevičius</span>

          <span className="review_location">
            Freelance Software Developer &amp; Indie Hacker
          </span>
        </footer>{" "}
      </Media.Item>
    </Media>
  )
}

export default UserReview
