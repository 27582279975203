import React from "react"
import { Link, graphql } from "gatsby"
import Page from "../../layouts/Page"
import { Card, Columns, Container, Heading } from "react-bulma-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaRegClock } from "react-icons/fa"

const formatDate = d => {
  const ye = new Intl.DateTimeFormat("fr", { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat("fr", { month: "short" }).format(d)
  const da = new Intl.DateTimeFormat("fr", { day: "2-digit" }).format(d)
  return `${da}-${mo}-${ye}`
}

const BlogCard = ({ post }) => {
  const publishedAt = new Date(post.frontmatter.date)

  return (
    <Columns.Column size="one-third">
      <Card>
        <Card.Header>
        <GatsbyImage
            image={post.frontmatter.vignette.childImageSharp.gatsbyImageData}
            alt={post.frontmatter.title}
          />
        </Card.Header>
        <Card.Content className="blog-post-card">
          <Link to={post.frontmatter.slug} className="post-title">
            <Heading className="has-text-black" size={5} renderAs="h2">
              {post.frontmatter.title}
            </Heading>
            <div className="published-at">{formatDate(publishedAt)}</div>
          </Link>
          <div className="beyond">
            <p className="description mb-2 ">{post.excerpt}</p>
          </div>
          <div className="buttons is-right ">
            <div className="timeread">
              <FaRegClock /> &nbsp; {post.timeToRead} min
            </div>
            <Link
              className="is-dark is-small button"
              to={post.frontmatter.slug}
              language="fr"
            >
              Lire plus
            </Link>
          </div>
        </Card.Content>
      </Card>
    </Columns.Column>
  )
}

const BlogIndex = props => {
  const { data } = props
  const blogposts = data.posts.edges
  return (
    <Page>
      <Container max breakpoint="desktop">
        <Heading renderAs="h1" size="3">
          Articles
        </Heading>
        <section>
          <Columns>
            {blogposts.map(({ node }, index) => (
              <BlogCard key={index} post={node} />
            ))}
          </Columns>
        </section>
      </Container>
    </Page>
  )
}

export default BlogIndex

export const query = graphql`
query ($skip: Int, $limit: Int) {
  posts: allMarkdownRemark(
    filter: {frontmatter: {published: {eq: true}}, sourceInstanceName: { eq: "articles" }}
    limit: $limit
    skip: $skip
    sort: {order: DESC, fields: frontmatter___date}
  ) {
    edges {
      node {
        timeToRead
        excerpt
        frontmatter {
          title
          slug
          date
          vignette {
            childImageSharp {
              gatsbyImageData(aspectRatio: 2, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
}
`
