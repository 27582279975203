import React from "react"
import { Link, graphql, navigate } from "gatsby"
import { Button, Content, Container, Heading } from "react-bulma-components"
import Page from "../layouts/Page"
import ShareButtons from "../components/ShareButtons"
import { Helmet } from "react-helmet"

const PostPage = props => {
  const { data, pageContext, location } = props
  const post = data.post

  const relativeUrl = `/news/${post.frontmatter.preslug}/${post.slug}`
  const url = `${location.origin}${relativeUrl}`
  const title = post.frontmatter.title
  const strDate = new Date(post.frontmatter.date).toJSON()
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": relativeUrl,
    },
    headline: title,
    datePublished: strDate,
    dateModified: strDate,
    author: {
      "@type": "Person",
      name: "Thierry Malo",
      givenName: "Thierry",
      familyName: "Malo",
    },
    description: post.excerpt,
    wordCount: post.wordCount.words,
  }

  return (
    <Page {...props}>
      <Helmet title={title}>
        <meta name="description" content={post.excerpt} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={post.excerpt} />
        <script
          key="news_ldjson"
          type="application/ld+json"
        >
          {JSON.stringify(jsonLd, null, 2)}
          </script>
      </Helmet>
      <Container
        max
        breakpoint="desktop"
        className="news_post"
        renderAs="article"
      >
        <article className="post single">
          <Heading renderAs="h1">
            <Link to={relativeUrl}>{title}</Link>
          </Heading>
          <Content
            className="post_content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <p className="byline">
            <span className="date">Le {post.frontmatter.pubDate}</span>
          </p>
          <div className="post-footer">
            <ShareButtons post={{ url, title }} />
          </div>
        </article>

        <Button.Group className="post-nav">
          {pageContext.previousItem !== null ? (
            <Button
              color="secondary"
              onClick={() => navigate(pageContext.previousPagePath)}
            >
              Précédent
            </Button>
          ) : (
            <div> </div>
          )}

          <div> </div>
          {pageContext.nextItem !== null ? (
            <Button
              color="secondary"
              onClick={() => navigate(pageContext.nextPagePath)}
            >
              Suivant
            </Button>
          ) : (
            <div> </div>
          )}
        </Button.Group>
      </Container>
    </Page>
  )
}

export default PostPage

export const pageQuery = graphql`
  query ($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      slug
      frontmatter {
        title
        preslug: date(formatString: "YYYY/MM")
        pubDate: date(formatString: "DD MMMM YYYY", locale: "fr")
        date
      }
      html
      excerpt
      wordCount {
        words
      }
    }
  }
`
