import React from "react"
import TopNavbar from "../components/TopNavbar"

const PageHeader = () => {
    return (
        <header className="header">
        <TopNavbar />
      </header>

    )
}
export default PageHeader