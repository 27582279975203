import React from "react"
import Page from "../layouts/Page"
import { Columns, Container, Heading,  } from "react-bulma-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SidebarAuthor from "../components/SidebarAuthor"

const StoryPage = () => {
  return (
    <Page>
      <Container
        max
        breakpoint="desktop"
        className="article"
        renderAs="article"
      >
        <Columns>
          <Columns.Column desktop size="three-fifths" offset={1}>
            <Heading renderAs="h1" size={3}>
              The Story Behind The Book
            </Heading>

            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque tempus fringilla euismod. Mauris viverra orci sed
                auctor sagittis. Nulla eu viverra ipsum. Fusce sit amet
                tincidunt ligula, at posuere mi. In cursus sem quis justo
                pellentesque commodo. In at mauris luctus, condimentum sapien
                at, porta nisi. Maecenas sollicitudin libero sit amet lobortis
                tempor.
              </p>
              <p>
                Quisque eget eros blandit, lacinia eros id, accumsan lectus.
                Praesent pulvinar quam nec pharetra viverra. Quisque pharetra
                orci urna, vel malesuada neque laoreet blandit. Morbi non nisi
                pellentesque, ultricies ante a, dignissim augue. Aliquam
                eleifend mauris elit, vitae sagittis felis fermentum eu. Ut
                bibendum odio nulla, nec vestibulum nisi aliquet eget. Donec
                venenatis erat vel quam sagittis tempus.
              </p>
              <p>
                Cras semper vestibulum quam sit amet fermentum. In finibus
                tortor metus, non commodo dolor pellentesque ut. Nulla quis
                varius neque, et imperdiet tortor. Nam id gravida dui, et
                pharetra augue. Suspendisse semper sapien nec ante accumsan, a
                interdum lorem condimentum. Cras pellentesque finibus ultrices.
                Praesent facilisis dignissim rutrum. Etiam egestas, dolor ac
                mollis mollis, augue ligula convallis odio, in iaculis urna arcu
                et odio. Fusce eget commodo ipsum. Cras eu odio volutpat,
                elementum tortor rhoncus, interdum dolor. Etiam varius lorem
                vitae congue viverra. Maecenas ac lacus nibh. Pellentesque
                habitant morbi tristique senectus et netus et malesuada fames ac
                turpis egestas. Nullam a volutpat urna.
              </p>
              <p>
                Vestibulum varius libero quis imperdiet tincidunt. Phasellus
                ipsum dolor, accumsan id massa nec, vehicula fermentum felis.
                Fusce vitae mauris a massa blandit rhoncus. Nulla vel eros ac
                purus commodo porta ut at nibh. Mauris at aliquam dui, id
                posuere odio. Donec placerat, lectus ut molestie venenatis,
                purus ipsum tincidunt nulla, sit amet porttitor ante ipsum sed
                sapien. Sed tempus viverra maximus. Aliquam imperdiet dignissim
                tincidunt. Aenean risus felis, convallis tincidunt dignissim
                vel, blandit vel sapien. Proin tristique est leo. Phasellus odio
                tellus, vestibulum nec convallis id, posuere sed mi. Praesent
                vitae ex tristique, porttitor risus a, semper elit. In hac
                habitasse platea dictumst. Nunc aliquet porttitor sodales.
                Mauris eu tristique urna.
              </p>
              <p>
                Fusce sed consequat erat, eu lobortis tortor. Vivamus nec
                pulvinar ipsum. Cras hendrerit lectus quis massa porta, id
                elementum nibh mattis. Nullam id augue ex. Mauris condimentum
                erat enim, at iaculis leo dignissim ut. Donec sodales enim non
                diam accumsan commodo. Orci varius natoque penatibus et magnis
                dis parturient montes, nascetur ridiculus mus. Nam sed mollis
                metus, eu consectetur arcu. Nunc at pharetra lectus, eget
                tristique nisl. Aliquam erat volutpat. Donec sagittis sagittis
                velit, quis vestibulum metus euismod eget. Donec iaculis quam in
                lectus commodo, ac condimentum ex laoreet. Donec vestibulum ante
                et erat commodo, id mattis orci vulputate. Sed dignissim ex a
                tortor dictum suscipit. Vivamus porta, risus vitae semper
                sagittis, eros orci tincidunt felis, id condimentum urna justo
                in lectus.
              </p>
              <p>
                Praesent eget massa vel dolor condimentum finibus semper et leo.
                Quisque eu nisi eget libero mollis elementum quis nec dui. Sed
                at dolor augue. In hac habitasse platea dictumst. Quisque porta
                ante ac rutrum pulvinar. Suspendisse consectetur varius arcu eu
                commodo. Aliquam eu condimentum leo, non laoreet est. Nunc
                luctus dolor in venenatis gravida. Cras eu mi purus. Vestibulum
                vitae nunc rhoncus massa tempor commodo. In ullamcorper vitae
                tortor quis auctor. Donec pulvinar, magna quis varius ornare,
                ante orci posuere sem, finibus vestibulum lacus quam quis nunc.
                Donec leo lacus, faucibus id pharetra eu, elementum in tortor.
              </p>
              <p>
                Donec a nisi nec quam aliquam lacinia. Donec eget dapibus augue,
                et venenatis dui. In nec efficitur libero. Aliquam auctor in
                orci ut tincidunt. Morbi nec nulla ut leo lobortis congue id nec
                risus. Interdum et malesuada fames ac ante ipsum primis in
                faucibus. Sed viverra risus sit amet egestas sagittis. Donec
                varius finibus ligula, at rutrum massa imperdiet a. Proin arcu
                lacus, mollis vitae vestibulum eu, tempus sit amet enim.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Fusce scelerisque dui elit, nec pulvinar
                leo lobortis eget.
              </p>
              <p>
                Proin id neque a sem luctus scelerisque in vitae urna. Integer
                posuere quis neque vel laoreet. Nam fermentum ornare neque.
                Maecenas commodo, leo et mollis vulputate, nibh lectus lacinia
                ex, ut hendrerit elit neque rhoncus diam. Nam semper lorem arcu,
                in euismod odio tincidunt et. Integer arcu neque, feugiat quis
                porttitor sed, tristique vel elit. Vestibulum eget vehicula
                erat. Sed ultrices pretium nibh ut volutpat.
              </p>
              <p>
                Nullam quis tincidunt felis. Aenean non iaculis ex. Sed varius
                finibus accumsan. Vivamus viverra, libero scelerisque interdum
                mollis, orci turpis vulputate tortor, in ornare erat velit vel
                massa. Nam aliquet quam vel odio porttitor ultrices. Maecenas
                tempor nisl et quam sollicitudin bibendum. Aenean nec placerat
                nulla. Pellentesque sodales auctor orci quis porttitor. In eget
                volutpat nisl. Maecenas vulputate quam nibh, et varius arcu
                pellentesque ut. Maecenas non tortor et ligula euismod placerat
                id at libero. Duis consectetur eget nunc sed commodo. Nullam
                dictum arcu venenatis ultrices mollis. Ut ut erat et urna
                sollicitudin vestibulum. Nam porttitor arcu a quam venenatis,
                vitae tincidunt nisi auctor. Maecenas luctus dui neque, at
                dignissim leo congue vel.
              </p>
              <p>
                Ut pharetra neque non nisl scelerisque rhoncus. Quisque sit amet
                mollis erat. Integer tellus est, sagittis a mi non, vehicula
                congue metus. Maecenas iaculis vel tellus quis feugiat.
                Suspendisse vel nisi ut sem pretium tristique nec ut metus.
                Pellentesque in nulla ut nisi dignissim mollis quis vel turpis.
                Fusce imperdiet vehicula elit, a accumsan ligula vestibulum nec.
                Vivamus quis viverra leo. Praesent tincidunt tortor a felis
                consequat, non fermentum diam ullamcorper. Praesent molestie,
                massa at convallis tincidunt, libero ligula scelerisque urna, id
                mattis ligula sem quis urna. Quisque pharetra sem vitae eleifend
                fermentum. Nam viverra magna magna, vel dapibus dui aliquet
                molestie. Fusce semper tellus sodales dolor dapibus scelerisque.
                In semper dolor felis, vitae tristique velit facilisis at. Nunc
                accumsan molestie leo, id maximus velit ornare vitae.
              </p>
            </div>

            <div className="book_block">
              <Columns>
                <Columns.Column size="one-fifth">
                  <StaticImage
                    src="../images/book_cover.jpg"
                    alt="book cover"
                    height={160}
                  />
                </Columns.Column>
                <Columns.Column size="three-quarters">
                  <div className="intro">
                    <Heading renderAs="h3" size="5">
                      Generate Product Ideas
                    </Heading>
                    <p className="description">
                      A highly practical book for finding ideas for your next
                      business, indie startup or a weekend project.
                    </p>
                    <Link to="/index" className="learn_more">
                      Learn more
                    </Link>
                  </div>
                </Columns.Column>
              </Columns>
            </div>
          </Columns.Column>
          <Columns.Column desktop size="one-fifth" renderAs="footer">
            <SidebarAuthor />
          </Columns.Column>
        </Columns>
      </Container>
    </Page>
  )
}

export default StoryPage
