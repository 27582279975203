import React from "react"
import PropTypes from "prop-types"
import { Heading } from "react-bulma-components"

const Audience = ({ title, children }) => (
  <section className="audience_section">
    <Heading renderAs="h2" size="3">
      {title}
    </Heading>
    {children}
  </section>
)

const List = ({ children }) => (
  <ul className="audience_list">
    {children}
  </ul>
)

Audience.List = List

const ListItem = ({ title, extra, items }) => {
  return (
    <li className="audience_list-item">
      <p className="audience_name">
        {title}{" "}
        {extra ? <span className="audience_name-extra">{extra}</span> : " "}
      </p>

      {items ? (
        <ul className="audience_tasks">
          {items.map((item, index) => {
            return (
              <li key={index} className="audience_tasks-item">
                {item}
              </li>
            )
          })}
        </ul>
      ) : (
        " "
      )}
    </li>
  )
}

Audience.ListItem = ListItem


Audience.ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Audience
