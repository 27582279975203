import React from 'react'
import { Link } from 'gatsby'
import {
  Box,
  Breadcrumb,
  Columns,
  Container,
  Form,
  Heading,
  Section,
} from 'react-bulma-components'
import Page from '../layouts/Page'
import SubmitForm from '../components/SubmitForm'
import { Helmet } from 'react-helmet'
const axios = require('axios').default

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      FNAME: '',
      LNAME: '',
      EMAIL: '',
      SUBJECT: '',
      MESSAGE: '',
      INTERESTS: ['article'],
      shallSubscribe: true,
      error: null,
      inprogress: false,
      success: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    if (target.type !== 'checkbox') {
      this.setState({
        [name]: value,
      })
    } else {
      if (target.name === 'INTERESTS') {
        if (target.checked) {
          var joined = this.state[name].concat(value)
          this.setState({
            [name]: joined,
          })
        } else {
          var netState = this.state[name].filter((v) => v !== value)
          this.setState({
            [name]: netState,
          })
        }
      } else {
        this.setState({
          [name]: target.checked,
        })
  
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ inprogress: true, error: null, success: false })
    const me = this
    const payload = {
      FNAME: this.state.FNAME,
      LNAME: this.state.LNAME,
      EMAIL: this.state.EMAIL,
      SUBJECT: this.state.SUBJECT,
      MESSAGE: this.state.MESSAGE,
      Subscribe: this.state.shallSubscribe,
      INTERESTS: this.state.INTERESTS,
    }

    if (this.state.city_field !== undefined) {
      const error = { code: 500, message: 'No robot allowed' }
      this.setState({ error, inprogress: false })
      return
    }

    axios
      .post('/api/contact', { 'form-name': 'contact', ...payload })
      .then(
        function (response) {
          me.setState({ inprogress: false, success: true })
        },
        (error) => {
          me.setState({ error: error.response.data, inprogress: false })
        }
      )
      .catch(function (error) {
        me.setState({ error, inprogress: false })
      })
  }

  render() {
    const { FNAME, LNAME, EMAIL, SUBJECT, MESSAGE, shallSubscribe } = this.state

    return (
      <Page location={this.props.location}>
        <Helmet title="Contacter Thierry Malo" />
        <Container max breakpoint="desktop">
          <Heading renderAs="h1" size={3}>
            Me contacter
          </Heading>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Me Contacter</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Section>
            <Box className="contact_form">
              <form
                data-netlify="true"
                data-netlify-honeypot="city_field"
                name="contact"
                onSubmit={this.handleSubmit}
              >
                {' '}
                <Columns>
                  <Columns.Column size="half">
                    <Form.Field>
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control>
                        <Form.Input
                          name="FNAME"
                          type="text"
                          value={FNAME}
                          required
                          onChange={this.handleInputChange}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  <Columns.Column size="half">
                    <Form.Field>
                      <Form.Label>Nom</Form.Label>
                      <Form.Control>
                        <Form.Input
                          name="LNAME"
                          type="text"
                          value={LNAME}
                          required
                          onChange={this.handleInputChange}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>

                  <Columns.Column size="full">
                    <Form.Field>
                      <Form.Label>Email</Form.Label>
                      <Form.Control>
                        <Form.Input
                          name="EMAIL"
                          type="email"
                          value={EMAIL}
                          required
                          onChange={this.handleInputChange}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>

                  <Columns.Column size="full">
                    <Form.Field>
                      <Form.Label>Sujet</Form.Label>
                      <Form.Control>
                        <Form.Input
                          name="SUBJECT"
                          type="text"
                          value={SUBJECT}
                          required
                          onChange={this.handleInputChange}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>

                  <Columns.Column size="full">
                    <Form.Field>
                      <Form.Label>Message</Form.Label>
                      <Form.Control>
                        <Form.Textarea
                          name="MESSAGE"
                          value={MESSAGE}
                          required
                          onChange={this.handleInputChange}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>

                  <Columns.Column size="full">
                    <Form.Field>
                      <Form.Control>
                        <Form.Checkbox
                          name="shallSubscribe"
                          onChange={this.handleInputChange}
                          checked={shallSubscribe}
                        >
                          Ajoutez moi à votre liste de diffusion
                        </Form.Checkbox>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
                <input
                  name="city_field"
                  style={{ left: '-1000px', position: 'absolute' }}
                  onChange={this.handleInputChange}
                />
                <SubmitForm
                  isSuccess={this.state.success}
                  hasError={this.state.error}
                  isInProgress={this.state.inprogress}
                  clearSuccess={() => this.setState({ success: false })}
                  clearError={() => this.setState({ error: null })}
                >
                  Envoyer
                </SubmitForm>
              </form>
            </Box>
          </Section>
        </Container>
      </Page>
    )
  }
}

export default ContactPage
