import * as React from "react"

function SvgMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M271.84 183.78l107.68 34a23.93 23.93 0 019.42 5.54c6.79 6.54 16.92 20.61 8.41 41.36a176.37 176.37 0 01-27.62 45.07L267 318.5l20.84-80.45s9.68-35.37-16-54.27z"
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </clipPath>
      </defs>
      <path
        d="M451.35 279.68q-.5-2.61-1.05-5.16c-9.16-43.09-27.61-87.59-60.5-118-14.25-13.15-31.48-23.2-50.68-26.82-23.44-4.41-47.81 1.7-69.35 12s-40.87 24.52-60.83 37.58c-12.89 8.43-26.52 16.55-41.73 19-18.21 2.93-36.84-2.57-55.21-.82-28.8 2.74-54.18 24.22-64.56 51.23s-6.62 58.36 7.06 83.86S91 377.91 116.84 391c35.75 18.16 78 23.84 116.9 14 19-4.8 37.34-13.2 56.9-13.55 24.26-.43 47 11.57 71 14.89 22.56 3.11 46.62-2 64.57-16 33.29-26 32.35-73.2 25.14-110.66z"
        fill="current"
      />
      <path
        d="M451.35 279.68q-.5-2.61-1.05-5.16c-9.16-43.09-27.61-87.59-60.5-118-14.25-13.15-31.48-23.2-50.68-26.82-23.44-4.41-47.81 1.7-69.35 12s-40.87 24.52-60.83 37.58c-12.89 8.43-26.52 16.55-41.73 19-18.21 2.93-36.84-2.57-55.21-.82-28.8 2.74-54.18 24.22-64.56 51.23s-6.62 58.36 7.06 83.86S91 377.91 116.84 391c35.75 18.16 78 23.84 116.9 14 19-4.8 37.34-13.2 56.9-13.55 24.26-.43 47 11.57 71 14.89 22.56 3.11 46.62-2 64.57-16 33.29-26 32.35-73.2 25.14-110.66z"
        fill="#fff"
        opacity={0.7}
      />
      <g fill="#fff">
        <path d="M381.21 174c-3.25-1.78-4.73-.3-5.91-1.78s3.25-5.32-5.62-9.16c0 0-.59-8.28-12.71-8.57s-8.87 8.27-12.12 10.34-4.14-1.18-7.39.89-1.77 6.21-4.43 6.8-6.21-2.66-9.46-.59-3.25 3.84-4.73 3.84-4.44-1.18-6.21 0-3.84 2.66-3.84 2.66h74.79s.88-2.71-2.37-4.43z" />
        <path
          d="M381.21 174c-3.25-1.78-4.73-.3-5.91-1.78s3.25-5.32-5.62-9.16c0 0-.59-8.28-12.71-8.57s-8.87 8.27-12.12 10.34-4.14-1.18-7.39.89-1.77 6.21-4.43 6.8-6.21-2.66-9.46-.59-3.25 3.84-4.73 3.84-4.44-1.18-6.21 0-3.84 2.66-3.84 2.66h74.79s.88-2.71-2.37-4.43z"
          opacity={0.5}
        />
        <path d="M254.55 370c-3.26-1.78-4.73-.3-5.92-1.78s3.25-5.32-5.61-9.16c0 0-.59-8.28-12.71-8.57s-8.87 8.27-12.12 10.34-4.14-1.18-7.39.89-1.78 6.21-4.44 6.8-6.21-2.66-9.46-.59-3.25 3.84-4.73 3.84-4.43-1.18-6.21 0-3.84 2.66-3.84 2.66h74.79s.89-2.71-2.36-4.43z" />
        <path
          d="M254.55 370c-3.26-1.78-4.73-.3-5.92-1.78s3.25-5.32-5.61-9.16c0 0-.59-8.28-12.71-8.57s-8.87 8.27-12.12 10.34-4.14-1.18-7.39.89-1.78 6.21-4.44 6.8-6.21-2.66-9.46-.59-3.25 3.84-4.73 3.84-4.43-1.18-6.21 0-3.84 2.66-3.84 2.66h74.79s.89-2.71-2.36-4.43z"
          opacity={0.5}
        />
        <path d="M194.12 337c-5.09-2.77-7.4-.46-9.25-2.77s5.08-8.33-8.79-14.34c0 0-.92-12.95-19.89-13.41s-13.87 12.95-19 16.19-6.47-1.85-11.56 1.38-2.78 9.72-6.94 10.64-9.71-4.16-14.8-.92-5.09 6-7.4 6-6.94-1.85-9.71 0-6 4.16-6 4.16h117s1.42-4.12-3.66-6.93z" />
        <path
          d="M194.12 337c-5.09-2.77-7.4-.46-9.25-2.77s5.08-8.33-8.79-14.34c0 0-.92-12.95-19.89-13.41s-13.87 12.95-19 16.19-6.47-1.85-11.56 1.38-2.78 9.72-6.94 10.64-9.71-4.16-14.8-.92-5.09 6-7.4 6-6.94-1.85-9.71 0-6 4.16-6 4.16h117s1.42-4.12-3.66-6.93z"
          opacity={0.5}
        />
      </g>
      <path
        d="M247.52 71.57c-34.91 0-63.3 25.47-64.29 57.22l-27.51 15.07-14.46-13.74-20.88 31.7-14.54-.62 7.46 28.93.65-23.81 10.29 1.93 17-17.89 17.35 13.74 27.19-17c7.84 24.44 32.49 42.3 61.72 42.3 35.53 0 64.34-26.38 64.34-58.92s-28.79-58.91-64.32-58.91z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={182.62}
        y={92.18}
        width={127.72}
        height={77.7}
        rx={5.83}
        fill="current"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M251.58 129.09l58.76-29.73V98a5.83 5.83 0 00-5.83-5.83H188.45a5.83 5.83 0 00-5.83 5.83v1.61l57.69 29.61a12.59 12.59 0 0011.27-.13z"
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <circle
        cx={305.97}
        cy={108.21}
        r={13.11}
        transform="rotate(-9.26 305.817 108.165)"
        fill="#263238"
      />
      <path
        d="M305.19 115.73a2.1 2.1 0 01-.47-1.46V102.6l-2.3 1.4a1.07 1.07 0 01-.61.18 1 1 0 01-.8-.37 1.19 1.19 0 01-.34-.85 1.15 1.15 0 01.62-1l4.19-2.47a2 2 0 011-.31 1.46 1.46 0 011.15.51 2.16 2.16 0 01.44 1.44v13.22a2 2 0 01-.47 1.39 1.55 1.55 0 01-1.2.52 1.48 1.48 0 01-1.21-.53z"
        fill="current"
      />
      <g>
        <path
          d="M183.5 281.76s39.5 59.5 49 60.5 39.5-30 39.5-30l-8.5-2-28.31 19.14S211 299.7 189.74 277z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M190.53 264s7.92.34 7.58 3.79 0 12.75-2.07 14.48-5.17 2.41-5.86-1 .35-17.27.35-17.27z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M188.46 280.23s-.35 10.34-1 11.37-5.52 4.83-8.62 4.14-2.07-3.79-2.07-5.51V263h11.72z"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.26 284s-2.41 4.48-.35 7.93 7.93 3.45 10.35 1.72.68-4.13-1.38-4.82-4.83-2.42-4.83-4.48-2.76-1.71-3.79-.35z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M170.39 277.46c0 2.36.4 6.32 2.26 8 2.1 1.85 11.76 2 16-.83s1.42-7.7-1.79-7.39c-2.66.26-5.51-.39-8.21-.73-1.52-.19-6.88-1.38-7.93-.27a1.73 1.73 0 00-.29 1.19z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M169.2 268.92c-.33 2.38-.48 6.4 1.47 8.14 2.21 2 13.66 2.56 19.07-.08s2.84-7.71-1-7.54c-3.19.13-6.48-.67-9.62-1.14-1.77-.26-8-1.71-9.37-.64a1.76 1.76 0 00-.53 1.19z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M169.87 257.14c-.85 2.25-1.89 6.14-.37 8.27 1.73 2.41 12.76 5.51 18.61 4.13s4.49-6.89.69-7.58c-3.14-.57-6.17-2.08-9.13-3.23-1.67-.65-7.38-3.43-9-2.7a1.74 1.74 0 00-.77 1z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M261.57 429.96l31.5-117.76h10.66l-33.44 118.73"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M282.43 418.7l30.51-106.98-9.21.48-33.44 118.73"
        />
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M279.5 428.99l1.41-4.94"
        />
        <path
          d="M240.5 339.86s3.45 5.17 7.59 5.86S296 311.94 296 311.94L278.07 314z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M212.71 284.58s8.24-68.33 26.66-92.08c20.13-26 60.58-4.84 52.34 36.83-6.32 32-24.72 89.17-24.72 89.17z"
          fill="current"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M271.84 183.78l107.68 34a23.93 23.93 0 019.42 5.54c6.79 6.54 16.92 20.61 8.41 41.36a176.37 176.37 0 01-27.62 45.07L267 318.5l20.84-80.45s9.68-35.37-16-54.27z"
          fill="#fff"
        />
        <g clipPath="url(#prefix__a)">
          <path
            d="M322.33 278.43c-25.9 12.14-39.75 10-46.64 6.5L267 318.5l102.74-8.72a176.37 176.37 0 0027.62-45.07 44.69 44.69 0 002.54-8.38c-24.97-5.88-44.77 6.73-77.57 22.1z"
            fill="current"
          />
          <path
            d="M322.33 278.43c-25.9 12.14-39.75 10-46.64 6.5L267 318.5l102.74-8.72a176.37 176.37 0 0027.62-45.07 44.69 44.69 0 002.54-8.38c-24.97-5.88-44.77 6.73-77.57 22.1z"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M271.84 183.78l107.68 34a23.93 23.93 0 019.42 5.54c6.79 6.54 16.92 20.61 8.41 41.36a176.37 176.37 0 01-27.62 45.07L267 318.5l20.84-80.45s9.68-35.37-16-54.27z"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M332.36 193.92l-16.86 52.62c-2.87.47-5.67 2.84-7.06 6.33-1.92 4.82-.43 9.95 3.33 11.45s8.37-1.2 10.29-6c1.58-4 .85-8.14-1.56-10.33l11.86-40.52 16.54 5.64 4.52-11.65zM229.33 240.54s7.52 8.28 7.9 12.41-6.39 15-6.39 15 13.91 8.65 27.07 2.63S277.46 239 277.46 239s-15.79 7.52-21.81 7.52-26.32-5.98-26.32-5.98z"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257.91 270.62c3.63-1.66 6.74-4.81 9.35-8.49a4.18 4.18 0 00-3.33-3.16c-7.9-1.51-16.17 7.14-16.17 7.14a25.1 25.1 0 00-1.34 6.6 24.45 24.45 0 0011.49-2.09z"
          fill="current"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M247 263.86a27.44 27.44 0 01-12.1-3.34c-1.83 3.86-4.07 7.47-4.07 7.47s13.91 8.65 27.07 2.63a23.86 23.86 0 008.84-7.79c-3.08.34-13.12 1.34-19.74 1.03zM255.65 246.56c-6 0-26.32-6-26.32-6a58.25 58.25 0 016.56 8.83c5 2 13.79 5.09 20.89 5.09a32.48 32.48 0 0017.22-5.13 88.1 88.1 0 003.46-10.35s-15.79 7.56-21.81 7.56z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M254.15 229.26s2.26-7.89 8.65-8.65 8.27 3.76 8.27 3.76-12.07 0-16.92 4.89z"
          fill="#263238"
        />
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M272.57 236.78l9.4 3.39"
        />
        <path
          d="M243.59 221.7c-.47 2.65-1.68 4.66-2.71 4.48s-1.46-2.48-1-5.14 1.69-4.67 2.71-4.49 1.48 2.45 1 5.15zM255.65 219.86s-.37-4.51 9.4-4.51 11.66-1.13 7.9-6-19.55-2.27-17.3 10.51zM251.89 206.33s-.75-6.4-6.76-6.4-9.41 7.15-9.78 4.89 1.88-10.15 10.15-10.9 6.39 12.41 6.39 12.41z"
          fill="#263238"
        />
        <path
          d="M294.32 201.11a42.37 42.37 0 012.9 12.67c2.4 27.43-22 102.2-22 102.2M284.87 190.11a14.67 14.67 0 015.53 4.4M369.82 302.8c-2.38 3.58-4 6-4 6M373.7 217.21s18.18 10.29 20.58 25.38-3.43 23-6.86 31.21c-2 4.71-8.77 15.5-14.22 23.86"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M231.76 275.66s5.44 5.43 17.66 4.07c0 0-12.22 5.43-17.66-4.07z"
          fill="#263238"
        />
        <path
          d="M121.94 271.27s31.83-16.69 89.93-6.46a10.25 10.25 0 0011.86-8.29l.84-4.69a10.25 10.25 0 00-5.89-11.16c-15.43-6.93-51.33-26.15-70.2-61.77z"
          fill="current"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M150.37 198.24s15.54 24.38 41.34 33.21"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M218.68 240.67a176.42 176.42 0 01-25.91-14.46c2.71 8.66 3.49 21.75-7.72 35.54a216.11 216.11 0 0126.82 3.06 10.25 10.25 0 0011.86-8.29l.84-4.69a10.25 10.25 0 00-5.89-11.16z"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx={127.21}
          cy={222.66}
          rx={55}
          ry={21.2}
          transform="rotate(-72.64 127.194 222.655)"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx={125.97}
          cy={222.27}
          rx={49.48}
          ry={17.77}
          transform="rotate(-72.64 125.957 222.27)"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.69 212.63l-9.3-.49-7 23.82 7.39 5.33a133.66 133.66 0 005.12-13.71 134.49 134.49 0 003.79-14.95z"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.92 225.22c-1.92 6.58-5.27 11.39-7.49 10.74s-2.45-6.5-.53-13.08 5.28-11.39 7.49-10.74 2.45 6.51.53 13.08z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M244.3 344.34c-5.17-2.41-47.57-77.9-50-82s-1.72-10 .69-15.51 2.41-15.86 1-17.93-5.63-4.18-5.63-4.18.81-1 2.53-3.05 5.86-.69 5.86-.69c3.1-2.42 5.87 1.52 5.87 1.52 3.45-2.07 5.16 1.92 5.16 1.92a8 8 0 013.45 1c2.87 1.86 2.41 10 2.41 10s3.1.69 3.79 2.76a17.12 17.12 0 01-.69 10c-1.38 3.45-10 13.79-10 13.79l42.74 73.42"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M198.8 221s4.95 3.19 4.95 6a54.13 54.13 0 00.55 6.48M204.67 222.47s3 3.15 3.15 5 .56 7.59.56 7.59M209.83 224.39a9.83 9.83 0 011.69 5.12 53.64 53.64 0 00.37 5.74M215.69 235.42s1.39 8.9.83 10.76"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default SvgMail
