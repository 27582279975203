import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useCallback, useState } from "react"
import { Container, Navbar } from "react-bulma-components"
const classNames = require("classnames")

const useToggle = (initialState = false) => {
  // Initialize the state
  const [state, setState] = useState(initialState)

  // Define and memorize toggler function in case we pass down the comopnent,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState(state => !state), [])

  return [state, toggle]
}

const TopNavbar = () => {
  const [mobileOpen, openMobileMenu] = useToggle(false)
  const navMenu = classNames({
    "is-active": mobileOpen,
  })

  const data = useStaticQuery(graphql`
    query TopMenu {
      site {
        siteMetadata {
          title
          description
        }
      }
      allMenuYaml(filter: { menu: { in: "top" } }) {
        nodes {
          title
          url
        }
      }
    }
  `)

  return (
    <Container max breakpoint="desktop">
      <Navbar>
        <Navbar.Brand>
          <div className="logo_text">
            <Link to="/">
              <h1 className="logo_title">{data.site.siteMetadata.title}</h1>
            </Link>
            <h2 className="logo_subtitle">
              {data.site.siteMetadata.description}
            </h2>
          </div>
        </Navbar.Brand>
        <Navbar.Burger onClick={openMobileMenu} aria-label="menu" />

        <Navbar.Menu renderAs="div" className={navMenu}>
          <Navbar.Container align="right">
            {data.allMenuYaml.nodes.map((node, index) => (
              <Navbar.Item key={index} href={node.url}>
                {node.title}
              </Navbar.Item>
            ))}
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    </Container>
  )
}

export default TopNavbar
